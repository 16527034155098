import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { Player } from "app/layout/Player";
import ParametersSection from "./parameters-section";
import RelatedProducts from "./related-products";
import placeholder from "app/assets/images/placeholder.png";
import { returnUrl } from "./options-section/modal";
import Price from "core/components/price/price";
import {
  MDBTypography,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBBox,
  ToastContainer,
  toast,
} from "mdbreact";
import "./_product-details-page.scss";
import ProductsSlider from "./slider";
import Options from "./options-section";
import OptionsModal from "./options-section/modal";
import { Msg } from "./messages";
import DownloadSection from "./downloadSection";
import QuoteRequestWishlistButton from "./quote-request-button";
import LifestyleImageAndVideo from "./lifestyle-image-video-section";
import ProductNameSection from "./product-name-section";
import { togglePdfBox, toggleModal } from "./togglers";
import Icon from "app/assets/icon/icon";
import Axios from "axios";
import ProductDescription from "./productDescription";
import ProductDescriptionMobile from "./productDescription-mobile";
import { useMediaQuery } from "react-responsive";
import ParametersSectionMobile from "./parameters-section-mobile/parametersSectionMobile";
// import _ from "lodash";
import SeoHelmet from "app/layout/seoHelmet";
import { ColorPicker, createColor } from "material-ui-color";

const ProductDetailsPage = ({ customerGroupId, ...props }) => {
  const [hasModel, setHasModel] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /* COLOR PICKER */
  const [colorPickerValue, setColorPickerValue] = useState("");
  const [colorPickerRALValue, setColorPickerRALValue] = useState("");
  /* COLOR PICKER */
  const { allSkus, custitem_closeout } = props;

  useEffect(() => {
    if (props.vars.CATA) {
      const url = `/models/${props.vars.CATA}.glb`;

      Axios.get(url)
        .then((response) => {
          if (
            response &&
            response.data &&
            !response.data.includes("!doctype")
          ) {
            setHasModel(true);
          } else {
            setHasModel(false);
          }
        })
        .catch((e) => {
          setHasModel(false);
        });
      window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
      return () => {
        window.removeEventListener("resize", () => {
          setWindowWidth(window.innerWidth);
        });
      };
    }
  }, [props]);

  let {
    questions,
    setVar,
    player,
    setPlayer,
    setVars,
    setSkuState,
    skuState,
    generated,
  } = props;

  const [visiblePdfBox, setVisiblePdfBox] = useState(false);
  const [modal, setModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [categorySku, setCategorySku] = useState("");
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [questionCode, setQuestionCode] = useState("");
  const [isPlayerActive, setIsPlayerActive] = useState(false);
  const [invalidOptions, setInvalidOptions] = useState([]);
  const [answers, setanswers] = useState([]);
  const [addToWishlist, , , , , , wishlist, , , , , ,] = useWishlist();
  const { isLoggedIn } = useCustomer();
  const [activeCatalogNo, setActiveCatalogNo] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const handleSetSelectedOptionsMap = useCallback(setSelectedOptionsMap, [
    setSelectedOptionsMap,
  ]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [varObject, setVarObject] = useState({});

  let catalogNumbers = props?.catalog_numbers;
  const isCatalog = questions.filter(
    (catalog) => catalog.name === "Catalog No."
  );

  let isTrackHead =
    typeof props.categories !== "undefined" && props.categories !== null
      ? props.categories.find((x) =>
          x.name.toLowerCase().includes("track heads")
        )
      : false;

  let isWallSconce =
    typeof props.categories !== "undefined" && props.categories !== null
      ? props.categories.find((x) =>
          x.name.toLowerCase().includes("wall sconces")
        )
      : false;

  let isCeilingSconce =
    typeof props.categories !== "undefined" && props.categories !== null
      ? props.categories.find((x) =>
          x.name.toLowerCase().includes("ceiling sconces")
        )
      : false;

  const catalogNoMap = isCatalog[0]?.parsedAnswers?.map((a) => {
    let ext = "";
    if (isWallSconce) {
      ext = "W";
    }
    const iconUrl = returnUrl(a?.sku, "CATA", ext);
    return { ...a, iconUrl };
  });

  useEffect(() => {
    const validSkus = questions
      ?.map((q) => q?.parsedAnswers?.map((a) => a?.sku))
      ?.map((sku) => sku.map((s) => s))
      ?.flat();

    const selectedSkus = Object.values(selectedOptionsMap).map(
      (item) => item.sku
    );

    const invalidSkusArray = selectedSkus.filter(
      (sku) => !validSkus.includes(sku)
    );
    const invalidOptions = Object.keys(selectedOptionsMap).filter((e) => {
      return invalidSkusArray.includes(selectedOptionsMap[e].sku);
    });
    setInvalidOptions(invalidOptions);
  }, [questions, selectedOptionsMap]);

  /*
   * Usage: first load reload
   * Set selectedOptionMap when empty
   * @selectedoptionsMap holding array of defined selecions in configurator
   */
  // if(isCatalog[0]?.parsedAnswers[0]?.sku !== undefined && Object.keys(selectedOptionsMap).length == 0) {
  //   console.log("answer 1");
  //   console.log("answers answers", questionCode);
  //   handleSetSelectedOptionsMap({CATA:isCatalog[0]?.parsedAnswers[0]})
  //   setVar("CATA", isCatalog[0]?.parsedAnswers[0].sku);
  //
  // }

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setVisiblePdfBox(false);
      }
    },
    [selectedOptionsMap]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  let productToAddToWishlist = {
    products: [{ sku: props.sku }],
    wishlists: [{ name: "My Favorites" }],
  };

  const wishlistItems =
    wishlist?.data?.wishlistItems && wishlist?.data?.wishlistItems[0]?.items;

  const isInWishlist = wishlistItems?.filter(
    (item) => item.product?.sku === props?.sku
  );

  const handleOptionClick = (options, modalTitle, categorySku) => {
    toggleModal(setModal, modal);
    setOptions(options);
    setModalTitle(modalTitle);
    setCategorySku(categorySku);
  };

  /*
  Usage: Handle selected options of configurator without catalog number exceptiong the catalogNo from @selectedOptionMap
  Restart: selected options of configurator without catalog number @handleSetSelectedOptionsMap
  Assign: answer to the questions
  Setting up @skuState
   */
  const handleSelectOption = (option, questionCode) => {
    let selectedMap = {
      ...selectedOptionsMap,
    };

    let selectedMapFinal = {};

    const selectedMapKeys = Object.keys(selectedMap);
    const indexOfQuestionCode = questions.findIndex(
      (x) => x.code === questionCode
    );

    selectedMapKeys.map((x, index) => {
      if (
        (x !== "CATA" && questions[indexOfQuestionCode].parsedAnswers.length) ||
        selectedMapKeys.includes(questionCode)
      ) {
        if (index < indexOfQuestionCode) {
          selectedMapFinal = {
            ...selectedMapFinal,
            [x]: { ...selectedMap[x] },
          };
        }
      } else if (x === "CATA") {
        selectedMapFinal = {
          ...selectedMapFinal,
          [x]: { ...selectedMap[x] },
        };
        //  handleCatalogNoSelectOption(option, questionCode);
      }
    });

    const imageUrl = returnUrl(option.sku, questionCode);

    selectedMapFinal = {
      ...selectedMapFinal,
      [questionCode]: { ...option, iconUrl: imageUrl },
    };

    let ans = [...answers];
    ans.push({ ...option, iconUrl: imageUrl });
    setanswers(ans);
    handleSetSelectedOptionsMap(selectedMapFinal);

    const selectedSkus = Object.values(selectedMapFinal).map(
      (item) => item.sku
    );

    const selectedSkusKeys = Object.keys(selectedMapFinal).map((item) => {
      return item;
    });

    const obj = {};
    selectedSkusKeys.forEach((key, i) => (obj[key] = selectedSkus[i]));
    setSkuState({ ...props.skuInitialState, ...obj });

    toggleModal(setModal, modal);
  };

  /*
 Usage: Handle selected options of configurator starting with catalogNo from @selectedOptionMap
 Restart: selected options of configurator from catalog number
 Setting up skuState @setSkuState
  */
  const handleCatalogNoSelectOption = (option, questionCode) => {
    let newObject = {};
    let objectToSet = {};
    const selectedMap = {
      ...selectedOptionsMap,
    };

    Object.assign(objectToSet, { CATA: option });
    Object.assign(newObject, { CATA: option.sku });

    const imageUrl = returnUrl(option.sku, questionCode);
    selectedMap[questionCode] = {
      ...option,
      iconUrl: imageUrl,
    };

    handleSetSelectedOptionsMap(objectToSet);

    setVarObject(newObject);

    setSkuState({ ...props.skuInitialState, ...newObject });
  };

  const handleAddToWishlist = () => {
    addToWishlist(productToAddToWishlist);
    toast(<Msg />);
  };

  const CreateImageLink = (image) => {
    var a = document.createElement("a");
    a.href = image;
    a.rel = "noopener noreferrer";
    a.target = "_blank";
    a.download = props?.skuState.CATA + ".png";
    a.click();
  };

  const save3DImage = (
    e,
    backupImage,
    savePosition = true,
    shouldDownload = true
  ) => {
    if (player && hasModel) {
      let initPosition = null;
      if (savePosition) {
        initPosition = player.getCameraPosition();
        player.setCameraPosition({ x: 50, y: -1, z: 50 });
      }
      player.update();
      e.stopPropagation();
      new Promise((resolve) => {
        let dataUrl = undefined;
        try {
          dataUrl = player.container.children[0].toDataURL() || undefined;
        } catch (e) {
          resolve(null);
        }
        resolve(dataUrl);
      }).then((blob) => {
        if (blob) {
          if (shouldDownload) {
            CreateImageLink(blob);
          }
          window.localStorage.setItem("blob", blob);
        }
      });
      if (savePosition && initPosition) {
        player.setCameraPosition(initPosition);
      }
    } else {
      if (shouldDownload) {
        CreateImageLink(backupImage);
      }
    }
  };
  // if(isCatalog[0]?.parsedAnswers[0]?.sku !== undefined && Object.keys(selectedOptionsMap).length == 0)
  if (
    isCatalog[0]?.parsedAnswers[0]?.sku &&
    !isPlayerActive &&
    isCatalog[0]?.parsedAnswers[0]?.sku !== undefined &&
    Object.keys(selectedOptionsMap).length == 0
  ) {
    let newObject = {};
    Object.assign(newObject, { CATA: isCatalog[0]?.parsedAnswers[0]?.sku });
    setIsPlayerActive(true);
    setVar("CATA", isCatalog[0]?.parsedAnswers[0]?.sku);
    setSkuState({ ...props.skuInitialState, ...newObject });

    handleCatalogNoSelectOption(isCatalog[0]?.parsedAnswers[0], "CATA");
  }

  const suspQ = questions.filter((q) => {
    return q.code === "SUSP";
  })[0];

  const needsAdapter = suspQ?.parsedAnswers.length > 1 || false;

  let finalVars = {
    ...props.vars,
    isTrackHead:
      typeof isTrackHead !== "undefined" && isTrackHead ? true : false,
    needsAdapter,
    isWallSconce:
      typeof isWallSconce !== "undefined" && isWallSconce ? true : false,
    isCeilingSconce:
      typeof isCeilingSconce !== "undefined" && isCeilingSconce ? true : false,
  };

  /**
   * accurately determine correct number of options required
   * fixes issue with quote request button not corresponding to UI alterations (based on current selections)
   */
  const activeQuestions = questions?.filter((x) => x.parsedAnswers.length > 0);
  let lengthOfQuestions = activeQuestions?.length;

  let lengthOfSelectedOptions = 0;
  let selectedOptionKeys = Object.keys(selectedOptionsMap);
  activeQuestions.map((x) => {
    if (selectedOptionKeys.includes(x.code)) {
      lengthOfSelectedOptions++;
    }
    return x;
  });
  const catNo = catalogNoMap?.[0]?.name || "";

  let saveOverviewSpecSheet = useMemo(
    () =>
      props?.custitem_pdf_attachment_1
        ?.trim()
        ?.replace("[", "")
        ?.replace("]", "")
        .replaceAll('"', ""),
    [props?.custitem_pdf_attachment_1]
  );

  const contactEmail = "contact@primalighting.com";

  return (
    <MDBContainer
      fluid
      className="text-center text-md-left typography-wrapper w-100 ml-0 mr-0 pl-0 pr-0"
      id="product-page-container"
    >
      <SeoHelmet
        title={props?.name}
        url={props?.url_key}
        description={props?.name}
      />
      <ToastContainer autoClose={3000} hideProgressBar />
      <MDBRow className="breadcrumbs-row w-100 no-gutters">
        <MDBCol size="12" lg="12">
          <ProductNameSection
            categories={props?.categories}
            name={props?.name}
            catalogNoMap={catalogNoMap}
          />
        </MDBCol>
        <MDBCol size="12" className="product-name-and-3D-icon">
          <MDBTypography tag="h1" className="h2 mb-2">
            {props?.name}
          </MDBTypography>
          {hasModel && <Icon icon={"newThreeD"} height="50px" width="50px" />}
        </MDBCol>
      </MDBRow>
      <MDBRow className="no-gutters main-row-pdp ml-0 mr-0 pl-0 pr-0 w-100">
        <MDBCol
          size="12"
          lg="4"
          md="12"
          sm="12"
          className="top-left-section p-0 ml-0 mr-0 pl-0 pr-0"
        >
          <Options
            handleCatalogNoSelectOption={handleCatalogNoSelectOption}
            setVar={setVar}
            price={props.price}
            questions={questions}
            invalidOptions={invalidOptions}
            handleOptionClick={handleOptionClick}
            setQuestionCode={setQuestionCode}
            selectedOptionsMap={selectedOptionsMap}
            catalogNoMap={catalogNoMap}
            catalogNumbers={catalogNumbers}
            activeCatalogNo={activeCatalogNo}
            setActiveCatalogNo={setActiveCatalogNo}
            setSelectedOptionsMap={handleSetSelectedOptionsMap}
            hasModel={hasModel}
            lengthOfQuestions={lengthOfQuestions}
            options={options}
            handleSelectOption={handleSelectOption}
            setVars={setVars}
            setVarObject={setVarObject}
            vars={props.vars}
            isWallSconce={isWallSconce}
            setSkuState={setSkuState}
            initialSkuState={props.skuInitialState}
            colorPicker={props.custitem_color_picker}
            colorPickerVal={setColorPickerValue}
            colorPickerRalVal={setColorPickerRALValue}
            custitem_pdf_attachment_1={saveOverviewSpecSheet}
          />
          <OptionsModal
            modal={modal}
            toggleModal={() => toggleModal(setModal, modal)}
            modalTitle={modalTitle}
            options={options}
            handleSelectOption={handleSelectOption}
            questionCode={questionCode}
            setVar={setVar}
            category={categorySku}
            lengthOfQuestions={lengthOfQuestions}
            hasModel={hasModel}
            selectedOptionsMap={selectedOptionsMap}
          />
          <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-3  w-100 pr-0">
            {allSkus.map((s) => {
              const k = Object.keys(s)[0];

              return (
                <MDBCol size="12">
                  <MDBRow>
                    <MDBCol className="col-6">
                      <span className="catalog text-bold" style={{}}>
                        {k.toUpperCase()}:{" "}
                      </span>
                      <span className="catalog">{s[k]}</span>
                    </MDBCol>

                    {customerGroupId === 4 &&
                      s.price > 0 &&
                      lengthOfQuestions <= lengthOfSelectedOptions && (
                        <MDBCol className="price col-6 mr-0 align-text-bottom">
                          <span style={{ fontSize: "1.6rem", float: "right" }}>
                            {selectedOptionsMap?.SYS?.sku === "MULTI_CS" ? (
                              <div className="customer-service-wrapper">
                                <span>
                                  Contact Customer Service for pricing at{" "}
                                </span>
                                <a href={`mailto:${contactEmail}`}>
                                  {contactEmail}
                                </a>
                              </div>
                            ) : (
                              <Price
                                price={
                                  k.toLowerCase() === "sku"
                                    ? s.price / 2
                                    : s.price
                                }
                                type="fixed"
                                onlyValues
                              />
                            )}
                          </span>
                        </MDBCol>
                      )}
                  </MDBRow>
                </MDBCol>
              );
            })}
          </MDBRow>
          <QuoteRequestWishlistButton
            selectedOptionsMap={selectedOptionsMap}
            questions={questions}
            isLoggedIn={isLoggedIn}
            handleAddToWishlist={handleAddToWishlist}
            isInWishlist={isInWishlist}
            customerGroupId={customerGroupId}
            sku={props.sku}
            catalogNumbers={catalogNumbers}
            selectedCustomizableOptionId={props.options}
            genratedSku={props?.genratedSku}
            catalogNumber={catNo}
            lengthOfQuestions={lengthOfQuestions}
            lengthOfSelectedOptions={lengthOfSelectedOptions}
            skusAndPrices={allSkus}
            getCanopyPrice={props.getCanopyPrice}
            colorPickerValue={
              colorPickerRALValue !== ""
                ? ["RAL " + colorPickerRALValue, colorPickerValue]
                : colorPickerValue
            }
            colorPickerRALValue={colorPickerRALValue}
          />
          <DownloadSection
            activeCatalogNo={activeCatalogNo}
            catalogNoMap={catalogNoMap}
            questionsNew={questions}
            data={props}
            activeQuestions={activeQuestions}
            image={props?.image?.url || placeholder}
            togglePdfBox={() => togglePdfBox(setVisiblePdfBox, visiblePdfBox)}
            visiblePdfBox={visiblePdfBox}
            files={props}
            closeBox={setVisiblePdfBox}
            save3DImage={save3DImage}
            selectedOptionsMap={selectedOptionsMap}
            skusAndPrices={allSkus}
            answers={answers}
            lengthOfQuestions={lengthOfQuestions}
            lengthOfSelectedOptions={lengthOfSelectedOptions}
            colorPickerValue={colorPickerValue}
            colorPickerRalValue={colorPickerRALValue}
            custitem_pdf_attachment_1={saveOverviewSpecSheet}
            custitem_closeout={custitem_closeout}
          />
        </MDBCol>
        <MDBCol
          size="12"
          lg="7"
          md="12"
          sm="12"
          className={`main-image custom-shadow top-middle-section ml-0 mr-0 pl-md-4 pr-md-4 ${
            lengthOfQuestions <= 1 && "no-sticky"
          }`}
        >
          {custitem_closeout ? (
            <div className={"closeout-item"}>Limited Inventory</div>
          ) : null}

          {!hasModel || isWallSconce ? (
            <>
              <img
                onClick={() => {
                  setFullscreen(!fullscreen);
                }}
                className={`product-image ${
                  fullscreen ? "fullscreen-canvas" : ""
                }`}
                src={
                  catalogNoMap &&
                  (props?.image?.url || catalogNoMap[activeCatalogNo].iconUrl)
                }
                alt="product"
              />
              {fullscreen ? (
                <MDBBox
                  className="close-fullscreen-img"
                  onClick={() => {
                    setFullscreen(!fullscreen);
                  }}
                >
                  <Icon icon={"minimize"} width="30px" height="30px" />
                </MDBBox>
              ) : (
                <MDBBox
                  className="enlarge-pdp"
                  onClick={() => {
                    setFullscreen(!fullscreen);
                  }}
                >
                  <Icon icon={"enlarge"} />
                </MDBBox>
              )}
            </>
          ) : (
            <>
              <div
                className={`player-wrapper ${
                  fullscreen ? "fullscreen-canvas" : ""
                }`}
              >
                <Player
                  vars={finalVars}
                  player={player}
                  setPlayer={setPlayer}
                  fullscreen={fullscreen}
                />

                {fullscreen ? (
                  <MDBBox
                    className="close-fullscreen"
                    onClick={() => {
                      setFullscreen(!fullscreen);
                    }}
                  >
                    <Icon icon={"minimize"} width="30px" height="30px" />
                  </MDBBox>
                ) : (
                  <>
                    <MDBBox className="three-d-icon-box">
                      <Icon
                        onClick={(e) => {
                          save3DImage(e, "", false);
                        }}
                        icon={"camera360"}
                      />
                    </MDBBox>
                    <MDBBox
                      className="enlarge-pdp-model"
                      onClick={() => {
                        setFullscreen(!fullscreen);
                      }}
                    >
                      <Icon icon={"enlarge"} />
                    </MDBBox>
                  </>
                )}
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol
          size="12"
          lg="1"
          md="12"
          sm="12"
          className="top-right-section"
          style={{
            position: "relative",
            top:
              props?.media_gallery_entries?.length > 3
                ? windowWidth > 991
                  ? props.custitem_color_picker == 1
                    ? "-180px"
                    : "-12px"
                  : "auto"
                : windowWidth > 991
                ? "120px"
                : "auto",
          }}
        >
          <ProductsSlider images={props?.media_gallery_entries} />
        </MDBCol>
      </MDBRow>
      <div className="description-wrapper">
        <MDBRow className="data-row no-gutters">
          <MDBCol className="description-and-size-wrapper" md="12" lg="6">
            {isMobile ? (
              <ProductDescriptionMobile {...props} />
            ) : (
              <ProductDescription {...props} />
            )}
          </MDBCol>
          <MDBCol md="12" lg="6" className="m-0 parameters-section p-0 w-100">
            {!isMobile ? <div className="h1">Parameters</div> : ""}
            <MDBBox className="md-accordion">
              {isMobile ? (
                <ParametersSectionMobile data={props} />
              ) : (
                <ParametersSection data={props} />
              )}
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </div>

      <LifestyleImageAndVideo image={props?.lifestyle_content} />
      {props.categories ? (
        <RelatedProducts currentCategory={props.categories} />
      ) : (
        <></>
      )}
    </MDBContainer>
  );
};

export default ProductDetailsPage;
