import React, { useState, useEffect } from "react";
import {
  MDBBox,
  MDBInput,
  MDBTableBody,
  MDBTypography,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBCardBody,
  MDBTable, MDBTableHead,
} from "mdbreact";
import Delete from "app/assets/images/delete.svg";
import Input from "app/core/components/qtyInput";
import { _ } from "lodash";
import { updateCartItemFn } from "./updateCartItemFn";
import { useMediaQuery } from "react-responsive";
import Price from "core/components/price/price";
import {useSelector} from "react-redux";


const QuoteRequestItem = ({
  key,
  item,
  removeItem,
  quantity,
  setQuantity,
  loading,
  setLoading,
  lock,
  updateCart,
  parsedOptions,
  sku,
  i,
  skuAndComments,
  setCurrentSkuAndComments,
  pairQty,
  isConnected = false,
  hasTopBorder,
  setColorPickerValueQR,
}) => {
  const customerGroupId = useSelector(
      (state) => state.customer.data.customer_group
  );
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const [activeCollapseDetails, setActiveCollapseDetails] = useState(true);
  const prices = getPrices(parsedOptions);
  // comments on each item
  const [comment, setComment] = useState("");

  let customPrice =
    prices &&
    prices?.map((p, i) => {
      const keys = Object.keys(p);
      let price;
      price = keys[0].toLowerCase() === "sku" ? p.price / 2 : p.price;

      return price;
    });

  skuAndComments[i] = {
    ...skuAndComments[i],
    comment: comment,
    remove_line: hasTopBorder ? 0 : 1,
    custom_price: parseFloat(customPrice),
  };
  useEffect(() => {
    setCurrentSkuAndComments(skuAndComments);
  }, [comment]);
  let productImage;
  if (typeof item.product.image?.url === "undefined") {
    productImage = item.product.small_image?.url;
  } else {
    productImage = item.product.image?.url;
  }

  useEffect(() => {
    if (item.quantity !== quantity) {
      setQuantity(item.quantity);
    }
  }, [item, quantity, setQuantity]);

  let hidePrice = false;

  const includesM =
    sku.includes("M3") || sku.includes("M4") || sku.includes("M5");

  parsedOptions.forEach((item) => {
    if (item?.question === "Multipoint Canopies System" || includesM) {
      hidePrice = true;
    }
    return hidePrice;
  });

  const contactEmail = "contact@primalighting.com";
  console.log("loading", loading);
  return (
    <MDBRow
      key={key}
      className={`quote-request-item ${
        hasTopBorder ? "has-top-border-quote-request" : ""
      } ${isConnected ? "" : "has-border-quote-request"}`}
    >
      <MDBCol md="3" className="main-image pt-5">
        <img
          src={
            typeof item.product.image?.url === "undefined"
              ? item.product.small_image?.url
              : item.product.image?.url
          }
          alt="product img"
        />
        <MDBTypography tag="h2" className="title-mobile">
          {item.product.name} <br />
          SKU:
          {/*{typeof parsedOptions[0] === "undefined"*/}
          {/*  ? sku*/}
          {/*  : parsedOptions[0].generated_sku.sku}*/}
        </MDBTypography>
      </MDBCol>
      <MDBCol md="9" className="details-holder pl-md-5">
        <MDBRow className="details-row align-items-center">
          <MDBCol size="7" className="mt-5 pt-5 mb-5 product-title">
            <MDBTypography tag="h2">
              SKU {item?.product?.name}
              {/* {typeof parsedOptions[0] === "undefined"
                ? sku
                : parsedOptions[0].generated_sku} */}
            </MDBTypography>
          </MDBCol>
          <MDBCol className="item-qty-container">
            <MDBRow>
              <MDBCol
                size="12"
                className={`d-flex align-items-center justify-content-end qty-inner-wrapper  ${
                  quantity === 1 ? " disable-minus" : ""
                }`}
              >
                <MDBBox className="number-input d-flex">
                  <p className="qty-quote-label">Qty</p>
                  <Input
                    disabled={loading}
                    type={"number"}
                    wrapperClass={"quantity-field"}
                    className={"qty-control-wrapper"}
                    inputClass={""}
                    placeholder={quantity}
                    plusClick={(e) => {
                      if (!loading) {
                        setLoading(`loading-${item.id}`);
                        updateCartItemFn(
                          item,
                          quantity + 1,
                          updateCart,
                          lock,
                          setQuantity,
                          setLoading
                        );
                      }
                    }}
                    minusClick={(e) => {
                      if (quantity > 1) {
                        if (!loading) {
                          setLoading(`loading-${item.id}`);
                          updateCartItemFn(
                            item,
                            quantity - 1,
                            updateCart,
                            lock,
                            setQuantity,
                            setLoading
                          );
                        }
                      }
                    }}
                    onBlur={(e) => {
                      let value = parseInt(e.target.value);
                      if (!loading) {
                        setLoading(`loading-${item.id}`);
                        updateCartItemFn(
                          item,
                          value,
                          updateCart,
                          lock,
                          setQuantity,
                          setLoading
                        );
                      }
                    }}
                    onChange={(e) => {
                      let value = parseInt(e.target.value);
                      if (!loading) {
                        if (value < 1 || value === "" || _?.isNaN(value)) {
                          setQuantity(1);
                        } else {
                          setQuantity(value);
                        }
                      }
                    }}
                  />
                </MDBBox>
                <MDBBox
                  className="m-0 p-0 mt-4 cursor-pointer quote-item-trash-icon"
                  onClick={() => {
                    if (!loading) {
                      setLoading(true);
                    }
                    removeItem(item.id);
                  }}
                >
                  <img src={Delete} className="ml-5 pr-2" alt="" />
                </MDBBox>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="md-accordion mt-2 p-sm-0">
            <MDBCard className="mt-1">
              <MDBCollapseHeader
                onClick={() => {
                  setActiveCollapseDetails(!activeCollapseDetails);
                }}
                tagClassName="d-flex justify-content-between"
              >
                <MDBTypography tag="h2">Details</MDBTypography>
                <MDBIcon icon={activeCollapseDetails ? "minus" : "plus"} />
              </MDBCollapseHeader>
              <MDBCollapse id="collapseDetails" isOpen={activeCollapseDetails}>
                <MDBCardBody>
                  <MDBTable className="details-table">
                    <MDBTableBody>
                      {parsedOptions.length > 0 ? parsedOptions.map((item) => {
                        let redStyle = {};
                        if (
                          item?.question === "Fixtures Selected" &&
                          pairQty !== item.name
                        ) {
                          redStyle = {
                            color: "red",
                          };
                        }
                        if (
                          item?.colorPicker &&
                          Object.keys(item?.colorPicker).length > 0
                        ) {

                          let colorPickerValueItem = item?.colorPicker;

                          // setColorPickerValueQR(colorPickerValueItem);
                          return (
                            <tr>
                              <td className="details-table-question">
                                Selected Color
                              </td>
                              <td className="text-right">
                                <div style={{ display: "inline-block" }}>
                                  <span className={"catalog-code"}>
                                    <div
                                      style={{
                                        backgroundColor: colorPickerValueItem instanceof Array ? colorPickerValueItem["1"] : colorPickerValueItem ,
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginRight: "10px",
                                      }}
                                      className={"colorpicker-circle wrap"}

                                    ></div>
                                    { colorPickerValueItem instanceof Array ? colorPickerValueItem["0"] : colorPickerValueItem}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                        if (item?.parsedOptions){
                              return (
                                <>
                                  {
                                    item?.parsedOptions.find( value => value.question === "Width") ? 
                                    <tr>
                                      <td
                                        className="details-table-question"
                                        style={redStyle}
                                      >
                                        {!isMobile
                                          ? "Width"
                                          : `Width`}
                                      </td>
                                      <td
                                        className="text-right details-table-answer"
                                        style={redStyle}
                                      >{item?.option_value.find( value => value.question === "Width").name}</td>
                                    </tr>
                                    : <></>
                                  }
                                  {
                                    item?.option_value.find(value => value.question === "Height") ? 
                                    <tr>
                                      <td
                                        className="details-table-question"
                                        style={redStyle}
                                      >
                                        {!isMobile
                                          ? "Height"
                                          : `Height`}
                                      </td>
                                      <td
                                        className="text-right details-table-answer"
                                        style={redStyle}
                                      >{item?.option_value.find(value => value.question === "Height").name}</td>
                                    </tr>
                                    : <></>
                                  }
                            </>
                          );
                        }
                        if (item.question) {
                          return (
                            <tr>
                              <td
                                className="details-table-question"
                                style={redStyle}
                              >
                                {!isMobile
                                  ? item.question
                                  : `${item.question.substring(0, 10)}...`}
                              </td>
                              <td
                                className="text-right details-table-answer"
                                style={redStyle}
                              >{`${
                                item?.name?.length > 16
                                  ? item?.name?.substring(0, 17) + "..."
                                  : item.question === "Fixtures Selected"
                                  ? pairQty + "/" + item.name
                                  : item.name
                              }`}</td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      }) : (
                          <tr>
                            <td className="details-table-question">Description</td>
                            <td className="text-right details-table-answer">{item?.product?.ark_custitem_prima_sales_desc}</td>
                          </tr>


                      )
                      }

                    </MDBTableBody>
                  </MDBTable>
                  {!includesM && (
                    <MDBBox className="mt-5 mb-5">
                      <MDBInput
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        value={comment}
                        maxLength={"250"}
                        type="textarea"
                        label="COMMENTS"
                        name="comments"
                        rows="7"
                        labelClass={`quote-label`}
                        placeholder="Enter your comments"
                        className={`quote-input`}
                      />
                    </MDBBox>
                  )}
                </MDBCardBody>
              </MDBCollapse>
              {hidePrice && prices &&
                prices?.map((p, i) => {
                  if (!p.price) {
                    return <></>;
                  }
                  const keys = Object.keys(p);
                  let price =
                    keys[0].toLowerCase() === "sku" ? p.price / 2 : p.price;

                  return (
                    <MDBRow className="mb-3 mx-0">
                      <MDBCol className="px-1 mx-2">
                        <span
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "1.7rem",
                            fontWeight: 500,
                            textTransform: "uppercase",
                          }}
                        >
                          {keys[0]}:{" "}
                        </span>
                        <span
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "1.5rem",
                            fontWeight: 100,
                          }}
                        >
                          {p[keys[0]]}
                        </span>
                      </MDBCol>
                      {!hidePrice ? (
                        <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                          <Price price={price} type="fixed" />
                        </MDBCol>
                      ) : (
                        <>
                          <div className="customer-service-wrapper">
                            <span>
                              Contact Customer Service for pricing at{" "}
                            </span>
                            <a href={`mailto:${contactEmail}`}>
                              {contactEmail}
                            </a>
                          </div>
                        </>
                      )}
                    </MDBRow>
                  );
                })
                }
                <MDBCol className="px-1 mx-2">
                  <span
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "1.7rem",
                        fontWeight: 500,
                        textTransform: "uppercase",
                        marginRight:"10px"
                      }}
                  >
                    SKU:
                  </span>
                  <span
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "1.5rem",
                        fontWeight: 100,
                      }}
                  >
                    {item?.product.sku}
                  </span>
                </MDBCol>
              {prices === false && item?.product?.price_range?.minimum_price?.regular_price?.value && (
                  <MDBRow className="mb-3 mx-0">
                    {!hidePrice ?   (
                            <>
                              {customerGroupId === 4 ? (
                                  <>
                                    { item?.product?.ark_closeout_price !== null && item?.product?.ark_closeout && item?.product?.ark_closeout === 1 &&
                                        (

                                            <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                                              <p style={{textAlign:"right", fontSize:"1.7rem", fontWeight:600, color:"#212529"}}><span style={{textAlign:"right", fontSize:"1.4rem", textDecorationLine: "line-through"}}>${item?.product?.price_range?.minimum_price?.regular_price?.value}</span> ${ parseFloat(item?.product?.ark_closeout_price).toFixed(2) }</p>
                                            </MDBCol>
                                        )}
                                    {item?.product?.price_tiers && Object.keys(item?.product?.price_tiers).length === 0 &&   (
                                        <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                                          <Price price={item?.product?.price_range?.minimum_price?.regular_price?.value} type="fixed" />
                                        </MDBCol>
                                    )}
                                    {item?.product?.price_tiers && Object.keys(item?.product?.price_tiers).length !== 0 && item?.product?.ark_closeout_price === null && !item?.product?.ark_closeout && item?.product?.ark_closeout !== 1 &&  (
                                        <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                                          {item?.product?.price_tiers && item?.product?.price_tiers?.map((tiers, index) => {

                                            let mqty = item?.product?.price_tiers[index-1]?.quantity ===  undefined ? 1 : item?.product?.price_tiers[index-1]?.quantity
                                            let mAXqty = item?.product?.price_tiers[index+1]?.quantity ===  undefined ? item?.product?.price_tiers[index].quantity : item?.product?.price_tiers[index+1]?.quantity
                                            let current =  item?.product?.price_tiers[index]?.quantity;

                                            return (
                                                <>{((mqty <= quantity && quantity < item?.product?.price_tiers[index].quantity)  )&& (

                                                    <Price price={item?.product?.price_tiers[index-1].final_price?.value} type="fixed" />
                                                )}
                                                  { quantity >= mAXqty && item?.product?.price_tiers[index+1]?.quantity ===  undefined && (

                                                      <Price price={item?.product?.price_tiers[index].final_price?.value} type="fixed" />
                                                  )}
                                                </>
                                            )

                                          })}
                                        </MDBCol>
                                    )}
                                  </>
                                  )
                                  :<></>}

                            </>

                    ) : (
                        <>
                          <div className="customer-service-wrapper">
                            <span>
                              Contact Customer Service for pricing at{" "}
                            </span>
                            <a href={`mailto:${contactEmail}`}>
                              {contactEmail}
                            </a>
                          </div>
                        </>
                    )}
                  </MDBRow>
              ) }
              {item?.product?.ark_closeout_price === null && !item?.product?.ark_closeout && item?.product?.ark_closeout === 0 &&  customerGroupId === 4 && (
                  <>
                    {item?.product?.price_tiers && item?.product?.price_tiers.length !== 0 && (
                        <>
                          <MDBTable className="availability-table-ark" responsive fixed>
                            <MDBTableHead>

                              <tr>
                                {item?.product?.price_tiers && item?.product?.price_tiers?.map((tiers) => {
                                  return <th style={{fontSize:"1.2rem"}} className="text-bold"><b>QTY {tiers?.quantity}+</b></th>
                                })}
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              <tr>
                                {item?.product?.price_tiers && item?.product?.price_tiers?.map((tiersP) => {
                                  return <th style={{fontSize:"1.2rem"}} >${tiersP?.final_price?.value.toFixed(2)}</th>
                                })}
                              </tr>
                            </MDBTableBody>
                          </MDBTable>
                          <span className="catalog" style={{fontSize: "1.1rem", fontWeight: "600"}}>*Freight allowance not included. Please ask for a freight quote. </span>
                        </>

                    )}

                  </>

              )}
        {!hidePrice && item.baba_eligible ? (
            <p className="babaDisclaimer">
              * While this product can be manufactured to be BABA compliant, pricing shown does not reflect this. If BABA compliance is important to you, please reach out to Prima Sales Support for assistance.
            </p>
          ) : null}
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

const getPrices = (options) => {
  const prices = options.find((i) => {
    return i?.prices || false;
  });
  if (!prices) {
    return false;
  }

  return prices.prices;
};

export default QuoteRequestItem;
