import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBBox,
  MDBCard,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBCardBody,
  MDBListGroup,
} from "mdbreact";
import BackGround from "app/assets/images/terms-conditions.png";
import SeoHelmet from "app/layout/seoHelmet";

const TermsConditions = () => {
  // TODO: pull data from module and remove hardcoded stuff
  let [activeCollapseStandardTerms, setActiveCollapseStandardTerms] =
    useState(true);
  let [activeCollapseShipping, setActiveCollapseShipping] = useState(true);
  let [activeCollapseReturns, setActiveCollapseReturns] = useState(true);
  let [activeCollapseReplacement, setActiveCollapseReplacement] =
    useState(true);
  // let isOpen = activeCollapse;

  return (
    <MDBBox className="main-row-terms-conditions">
      <SeoHelmet
        title={"Terms and Conditions"}
        url={"/terms-and-conditions"}
        description={"Terms and Conditions"}
      />
      <MDBBox
        className="privacy-policy"
        style={{
          backgroundImage: `url(${BackGround})`,
          backgroundRepeat: " no-repeat",
          backgroundPosition: "left top",
        }}
      >
        <MDBContainer>
          <MDBTypography tag="h1" variant="h1">
            Our Terms of Use
          </MDBTypography>

          <MDBBox className="md-accordion">
            <MDBCard>
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                <MDBTypography tag="h2" variant="h4">
                  Standard Terms
                </MDBTypography>
                <MDBIcon
                  icon={activeCollapseStandardTerms ? "minus" : "plus"}
                  onClick={() => {
                    setActiveCollapseStandardTerms(
                      !activeCollapseStandardTerms
                    );
                  }}
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={activeCollapseStandardTerms}>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol>
                      <MDBListGroup className="vertical-list">
                        <li>
                          All prices are in U.S. dollars. Net 30 days for credit
                          approved orders within U.S.
                        </li>
                        <li>
                          Prices are firm for 3 months from the date of order
                          acceptance. All hold-for-release orders not released
                          for shipment within 3 months (from the date of order
                          acceptance) will be subject to a price increase at
                          Prima’s discretion.
                        </li>
                        <li>
                          All hold-for-release orders not released for shipment
                          within 6 months (from the date of order acceptance)
                          shall be cancelled and subject to a 25% ~ 100%
                          cancellation fee plus any reasonable charges or
                          expenses from commitments made by Prima Lighting.
                        </li>
                        <li>
                          Any order or portion of an order cancelled more than
                          seven (7) days after Prima acceptance or within two
                          (2) business days of Prima’s scheduled shipment date
                          will be subject to a 25% ~ 100% cancellation charge.
                        </li>
                        <li>
                          A 2% interest fee per month will be added to all
                          outstanding balances beyond approval terms.
                        </li>
                        <li>
                          $30.00 bounced check fee will apply for each overdraft
                          transaction.
                        </li>
                        <li>
                          Verbal orders (or cancellations) are NOT accepted.
                        </li>
                      </MDBListGroup>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBBox>

          <MDBBox className="md-accordion">
            <MDBCard>
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                <MDBTypography tag="h2" variant="h4">
                  Shipping
                </MDBTypography>
                <MDBIcon
                  icon={activeCollapseShipping ? "minus" : "plus"}
                  onClick={() => {
                    setActiveCollapseShipping(!activeCollapseShipping);
                  }}
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={activeCollapseShipping}>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol>
                      <MDBListGroup className="vertical-list">
                        <li>
                          All orders are shipped free on board (FOB), Van Buren,
                          Arkansas
                        </li>
                        <li>
                          All returns are customer freight prepaid to the
                          location designated on return authorization.
                        </li>
                        <li>
                          Orders that include items not currently in stock will
                          ship as partial unless otherwise specified.
                        </li>
                        <li>
                          All claims for loss, damage, or shortage must be made
                          within 2 weeks after delivery.
                        </li>
                      </MDBListGroup>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBBox>

          <MDBBox className="md-accordion">
            <MDBCard>
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                <MDBTypography tag="h2" variant="h4">
                  Returns
                </MDBTypography>
                <MDBIcon
                  icon={activeCollapseReturns ? "minus" : "plus"}
                  onClick={() => {
                    setActiveCollapseReturns(!activeCollapseReturns);
                  }}
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={activeCollapseReturns}>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol>
                      <MDBListGroup className="vertical-list">
                        <li>
                          No return will be accepted for credit without prior
                          approval in writing. To obtain an RMA number, please
                          email all requests with the original invoice attached.
                        </li>
                        <li>
                          All returns for credit are subject to inspection and
                          must be in original factory packaging. All products
                          must be returned in sellable condition, indicating
                          RMA# on the carton, within 30 days for proper credit.
                        </li>
                        <li>
                          All returns will be subject to a minimum restocking
                          fee of 25%-50% charged at Prima’s discretion.
                        </li>
                        <li>
                          Credit will not be issued for materials purchased over
                          90 days prior to the request.
                        </li>
                        <li>No refunds will be made against credits.</li>
                        <li>
                          No returns will be accepted that are freight collect.
                        </li>
                      </MDBListGroup>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBBox>
          <MDBBox className="md-accordion">
            <MDBCard>
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                <MDBTypography tag="h4" variant="h4">
                  Limited Replacement Guarantee
                </MDBTypography>
                <MDBIcon
                  icon={activeCollapseReplacement ? "minus" : "plus"}
                  onClick={() => {
                    setActiveCollapseReplacement(!activeCollapseReplacement);
                  }}
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={activeCollapseReplacement}>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol>
                      <MDBListGroup className="vertical-list">
                        <li>
                          With proper installation, maintenance, and under
                          normal conditions of use, Prima Lighting warrants all
                          products manufactured by Prima Lighting to be free of
                          defects in materials and workmanship as follows:
                          <li style={{ marginLeft: "2rem" }}>
                            All Linear LED Strips are warranted for three years.
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                            If sold at regular DN pricing, all other LED related
                            components, LED transformers, and drivers are
                            warranted for five years
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                            All items sold at promotional or closeout prices are
                            warranted for one yea
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                            Removable lamps are covered only under their own
                            manufacturer’s warranty.
                          </li>
                        </li>
                        <li>
                          All products must be installed and/or used in
                          accordance with applicable National or Local Electric
                          Codes. Prima Lighting recommends a dedicated circuit
                          for low voltage systems and in no circumstances should
                          a low voltage system be combined with fluorescent
                          systems within the same circuit. Always follow any
                          specific instructions included with each fixture.
                        </li>
                        <li>
                          Prima Lighting will not repair or replace products
                          damaged by improper use or faulty installations.
                          Failures due to the use of incorrect lamps are not
                          covered by warranty.
                        </li>
                        <li>
                          Normal wear and tear on the fixture are not covered by
                          this warranty. Exterior or mechanical damage that is
                          not due to a warranty defect will not be corrected. No
                          cosmetic repairs will be made unless specifically
                          identified by Prima Lighting as a defect.
                        </li>
                        <li>
                          This warranty only applies when all components,
                          including power supplies, have been provided by Prima
                          Lighting. Substituting another manufacturer’s product
                          will render the warranty completely void. Furthermore,
                          this warranty does not apply to Prima Lighting
                          product(s) which have been altered, repaired, or
                          subjected to neglect, abuse, misuse, or accidents
                          (including shipping damages).
                        </li>
                        <li>
                          In no event shall Prima Lighting’s obligation under
                          this warranty extend beyond the initial cost of the
                          products and accordingly any consequential damages or
                          labor costs arising out of a defect are expressly
                          excluded. No charge backs, charges for labor, or
                          charges for materials will be honored without Prima
                          Lighting’s prior written consent.
                        </li>
                        <li>
                          For all warranty claims, Prima Tech Support,
                          800-437-7656, must be contacted first. Failure to
                          contact Prima Tech Support may void the warranty
                          coverage.
                        </li>
                        <li>
                          Prima Tech Support will assist all customers with
                          product troubleshooting and, if required, initiate the
                          product replacement process. Any replacement
                          product(s) provided may be invoiced or require
                          prepayment. When the product subject to the warranty
                          claim is returned for evaluation, Prima will issue
                          credit once the product is reviewed and evaluated as a
                          covered warranty claim.
                        </li>
                        <li>
                          Prima strongly suggests saving initial measurements of
                          the system’s voltage and temperature after
                          installation to be provided as a base line back to
                          Prima for warranty registration. Failure to provide
                          such data may result in void of warranty.
                        </li>
                        <li>
                          Prima Lighting does not guarantee the availability of
                          any item listed and reserves the right to discontinue
                          or change the technical/design specifications of its
                          products at any time without notice.
                        </li>
                        <li>
                          Prima Lighting will repair or replace, at our
                          discretion, any product upon confirmation of a defect
                          or failure. If products returned for warranty
                          evaluation are found to not be covered under our
                          warranty, Prima will NOT issue credit. The customer
                          may request that product be returned at customer’s
                          expense. Repaired and/or replaced units will be
                          shipped via ground service only.
                        </li>
                      </MDBListGroup>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBBox>
          <MDBRow className="bottom-paragraph-row">
            <MDBCol size="12" md="8">
              <MDBBox tag="p" className="bottom-paragraph">
                All inquiries are welcomed and estimates on custom orders can be
                provided if detailed drawings are supplied.
              </MDBBox>
              <MDBBox tag="p" className="bottom-paragraph">
                Please contact us for any customer support:{" "}
                <a href={`mailto:contact@primalighting.com `}>
                  contact@primalighting.com
                </a>
                , or call us toll free at{" "}
                <a href={`tel:  (866) 885 4915.`}> (866) 885 4915.</a>
              </MDBBox>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBBox>
    </MDBBox>
  );
};

export default TermsConditions;
